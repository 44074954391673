//import Vue from 'vue'
//import {BootstrapVue,IconsPlugin} from 'bootstrap-vue'
//import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/css/bootstrap-vue.css'
import "@fortawesome/fontawesome-svg-core"
import { createApp } from 'vue'
import App from './App.vue'

const app=createApp(App)
//Vue.use(BootstrapVue)
//Vue.use(IconsPlugin)
app.mount('#app')